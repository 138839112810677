import { useLocation, useNavigate } from "react-router-dom";
import { useLoggedUser } from "@store";
import { ReactNode, useState } from "react";
import {
  Dropdown,
  Frame,
  MenuItem,
  SubMenu,
  Suspense,
  useTranslation,
} from "h11-client-component-lib";
import { useLogout } from "@comm/oAuth";
import { OwnProfileUpdateDialog } from "./pages/users/own/OwnProfileUpdateDialog";
import { UserAvatar } from "@shared/ui/UserAvatar";
import { HotelSelector } from "@shared/ui/HotelSelector";

export function H11AppFrame({ children }: { children: ReactNode }) {
  const navigate = useNavigate();
  const location = useLocation();
  const loggedUser = useLoggedUser();
  const { t, tp } = useTranslation();

  // FIXME nějak vyřešit, aby loggedUser byl dolů předáván jako vždy non-null?

  const [editProfileDialogOpen, setEditProfileDialogOpen] = useState(false);

  const logout = useLogout();

  return (
    <Frame>
      <Frame.TopBar>
        <Frame.TopBar.Center>
          <HotelSelector />
        </Frame.TopBar.Center>
        <Frame.TopBar.Right>
          {loggedUser && (
            <Dropdown>
              <Dropdown.Control>
                <div className="profile">
                  <UserAvatar user={loggedUser} size="medium" />
                  <span>{`${loggedUser.firstName} ${loggedUser.lastName}`}</span>
                </div>
              </Dropdown.Control>
              <Dropdown.Menu>
                <Dropdown.Item onSelect={() => setEditProfileDialogOpen(true)}>
                  {t("profile_edit")}
                </Dropdown.Item>
                <Dropdown.Item
                  onSelect={() =>
                    // FIXME hotel z kontextu
                    logout().catch(() => {})
                  }>
                  {t("logout")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </Frame.TopBar.Right>
      </Frame.TopBar>
      <Frame.SideMenu
        onItemSelected={id => {
          navigate(id);
        }}
        selectedItemId={location.pathname}>
        <MenuItem itemId={"dashboard"} label={t("dashboard")} />
        <MenuItem itemId={"reservation"} label={t("reservations")} />
        <SubMenu label={t("enums")} dropdownId="enums">
          <MenuItem itemId={"f_and_b"} label={t("f_and_b")} />
          <MenuItem itemId={"tag"} label={t("tags")} />
          <MenuItem itemId={"discount"} label={t("discounts")} />
          <MenuItem itemId={"country"} label={tp("country", 2)} />
          <MenuItem itemId={"package"} label={t("packages")} />
          <MenuItem itemId={"room"} label={t("rooms")} />
          <MenuItem itemId={"user"} label={tp("user", 2)} />
          <MenuItem itemId={"role"} label={t("user_roles_short")} />
          <MenuItem
            itemId={"notification_group"}
            label={t("notification_groups")}
          />
          <MenuItem itemId={"local_fee"} label={t("local_fees")} />
          <MenuItem
            itemId={"reservationSource"}
            label={t("reservations_sources")}
          />
        </SubMenu>
      </Frame.SideMenu>
      <Frame.Main>
        <Suspense size={300}>{children}</Suspense>
        {loggedUser && (
          /*FIXME ne UserUpdateDialog ale vlastní, osekaný dialog na editaci profilu*/
          <OwnProfileUpdateDialog
            open={editProfileDialogOpen}
            onClose={() => setEditProfileDialogOpen(false)}
          />
        )}
      </Frame.Main>
    </Frame>
  );
}
