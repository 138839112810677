import { graphql } from "react-relay";

// FIXME userList a reservationSourcesList dát jinam - je na to článek v doc relay ohledně toho, aŤ argumenty ovlivňují jen část celého dotazu
export const reservationsPageEnumsQuery = graphql`
  query ReservationsPageEnumsQuery {
    saleCodeList {
      _id: id
      code
      name
    }
    guestCodeList {
      _id: id
      code
      name
    }
    reservationSourceList {
      _id: id
      code
      name
    }
    spaceTypeList {
      ... on SpaceType {
        _id: id
        code
        name {
          value
        }
      }
    }
    userList {
      userUid
      firstName
      lastName
      avatar {
        url
      }
    }
    reservationTagList {
      _id: id
      title
      color
    }
  }
`;
