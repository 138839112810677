import { ReservationStatus } from "@relay-generated/ReservationsQuery.graphql";
import { ReservationsFragment$data } from "@relay-generated/ReservationsFragment.graphql";
import { ReactNode } from "react";

export type GroupedStateFilterType =
  | "all"
  | "accommodated"
  | "valid"
  | "cancelled";

export interface GroupedStateFilter {
  id: GroupedStateFilterType;
  states?: ReservationStatus[];
}

export const groupedStateFilters: GroupedStateFilter[] = [
  {
    id: "all",
    states: undefined,
  },
  {
    id: "accommodated",
    states: undefined /*FIXME sem přibude bydlící a částečně bydlící*/,
  },
  {
    id: "valid",
    states: ["FIX"],
  },
  {
    id: "cancelled",
    states: ["CANCELLED"],
  },
];

export type ReservationTableItem =
  ReservationsFragment$data["reservationsOverviewList"]["edges"][number]["node"];

export type HighlightedReservationTableItem = ReservationTableItem & {
  nameHighlighted: ReactNode;
  numberHighlighted: ReactNode;
  orderNumberHighlighted: ReactNode;
};
