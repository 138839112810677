import { Table, useFilterAndHighlight } from "h11-client-component-lib";
import { useMemo } from "react";
import { PreloadedQuery, usePreloadedQuery } from "react-relay";
import { ColumnDef } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { RoleTableItem } from "./roleData";
import { RolesQuery } from "@relay-generated/RolesQuery.graphql";
import "./index.scss";
import { rolesQuery } from "./graphql/RolesQuery";
import { BasicRowAction, useBasicButtonsColumns } from "../BasicEnumPage";

export function RolesTable({
  queryRef,
  onRowAction,
  search,
}: {
  queryRef: PreloadedQuery<RolesQuery>;
  onRowAction: (action: BasicRowAction, user: RoleTableItem) => void;
  search: string;
}) {
  const { t } = useTranslation();

  const data = usePreloadedQuery(rolesQuery, queryRef);

  const roles = useMemo(() => [...data.roleList!], [data]);
  const systemRoles = useMemo(() => data.systemRoleList!, [data]);

  const filteredRoles = useFilterAndHighlight(roles, search, "name");

  type TableItem = (typeof filteredRoles)[number];

  const basicButtonsColumns = useBasicButtonsColumns<TableItem>(onRowAction);

  const columns: ColumnDef<TableItem>[] = [
    {
      accessorKey: "name",
      header: t("name"),
      cell: c => c.row.original.nameHighlighted,
    },
    {
      header: t("system_role"),
      accessorKey: "systemRole",
      cell: r => systemRoles.find(s => s.code === r.getValue())?.name,
    },
    ...basicButtonsColumns,
  ];

  return (
    <Table
      tableId="roles"
      columns={columns}
      defaultSorting={{ id: "name", desc: false }}
      data={filteredRoles}
    />
  );
}
